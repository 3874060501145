import dayjs from 'dayjs';

export const CLIENT_DATE_FORMAT = 'DD.MM.YYYY';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const CLIENT_DATE_WITH_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const APP_NAME = process.env.REACT_APP_NAME;

export const APP_LANGUAGE_KEY = `${process.env.REACT_APP_NAME}-language`;

export const BEGIN_RENT_DATE = dayjs(1722902400000);
